<template>
    <section class="h-100">
        <header-page :title="titulo">
            <div class="col-auto ml-auto">
                <div v-loading="loading" class="bg-general text-white f-18 br-12 py-2 px-5 cr-pointer" @click="guardarCarpeta">
                    Guardar
                </div>
            </div>
        </header-page>
        <div class="custom-scroll overflow-auto pt-4 pb-4" style="height:calc(100vh - 205px);">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 h-100">
                    <div class="col text-center flex-column border-right">
                        <div class="row mx-0 j-center">
                            <div class="br-12 position-relative" style="width:487px;height:278px:">
                                <div class="position-absolute bg-white br-12 py-2 px-3 shadow cr-pointer" style="bottom:5px;right:5px">
                                    Cambiar Imagen
                                </div>
                                <slim-cropper ref="slimImagen" :options="slimOptions_imagen" class="border slim-styles cr-pointer" style="" />
                            </div>
                            <div class="col-12 my-4 mt-5">
                                <ValidationProvider v-slot="{errors}" :rules="{required:true,max:120,duplicado:unicoNombre}" name="nombre">
                                    <el-input v-model="model.nombre" placeholder="Nombre" maxlength="121" class="input-name w-100 " />
                                    <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 my-4">
                                <ValidationProvider v-slot="{errors}" rules="required|max:1200" name="texto">
                                    <el-input
                                    v-model="model.descripcion"
                                    type="textarea"
                                    :rows="10"
                                    class="br-12 area-radius input-pr"
                                    placeholder="Texto..."
                                    maxlength="1201"
                                    />
                                    <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col border-left">
                        <div class="row mx-0 justify-content-end">
                            <div class="col-auto">
                                <div v-if="model.id" class="bg-white text-gris br-12 py-2 px-4 shadow cr-pointer" @click="crearCurso">
                                    Crear Curso
                                </div>
                            </div>
                            <div class="col-12 text-left f-600 text-gris f-600">
                                Cursos
                            </div>
                            <div v-if="cursos.length <=0" class="col-12 ">
                                No se han creado cursos
                            </div>
                        </div>
                        <draggable
                        :list="cursos"
                        handle=".handle"
                        class="list-group"
                        ghost-class="ghost"
                        @change="actualizarPosicion"
                        >
                            <div v-for="(curso, c) in cursos" :key="c" class="row mx-0 mt-2">
                                <div class="col-auto d-middle">
                                    <i class="icon-drag f-23 handle cr-pointer" />
                                </div>
                                <div class="col f-18 text-gri d-middle">
                                    {{ curso.nombre }}
                                </div>
                                <div class="col-2 d-middle j-center px-1">
                                    {{ curso.temas_count }} Temas
                                </div>
                                <div class="col-2 d-middle text-general j-center px-1">
                                    <i class="icon-pencil cr-pointer" @click="editarCurso(curso.id)" />
                                </div>
                                <div class="col-2 d-middle text-general j-center px-1">
                                    <i class="icon-delete f-22 cr-pointer" @click="eliminarCurso(curso.id)" />
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </ValidationObserver>
            <modalCrearCurso ref="modalCrearCurso" @actualizar="listarCarpeta" />
        </div>
    </section>
</template>

<script>
import Carpetas from '~/services/cursos/carpetas'
import Aws from '~/services/aws'
import { extend } from 'vee-validate';

extend('duplicado', {
    validate(value, items){
        let existe = false;
        items.forEach(info =>{
            if(_.toLower(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "") == _.toLower(info.nombre).normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
                existe = true;
            }
        });
        return !existe
    },
    message:
    "Ya existe otra carpeta con el mismo nombre."
});
export default {
    components:{
        modalCrearCurso: ()=> import('~/pages/misCursos/partials/modalCrearCurso')
    },
    layout: 'fullscreen',
    data(){
        return {
            id_carpeta: parseInt(this.$route.params.id_carpeta),
            model:{
                id:null,
                nombre : '',
                imagen : '',
                descripcion : ''
            },
            slimOptions_imagen: {
                ratio: '2:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            titulo: '',
            cursos: [],
            modoEditar: false,
            nameCarpetas: [],
            loading: false
        }
    },
    computed:{
        unicoNombre(){
            if(this.model.id !== null){
                return this.nameCarpetas.filter(e => e.id !== this.model.id)
            }
            return this.nameCarpetas
        },
    },
    mounted(){
        this.limpiarForm()
        this.listarNombresCarpetas()
        if(this.id_carpeta){
            this.titulo = 'Editar Carpeta'
            this.modoEditar = true
            this.listarCarpeta()
        }
    },
    methods: {
        async listarNombresCarpetas(){
            try {
                const {data: {data}} = await Carpetas.listarNombreCarpetas()
                this.nameCarpetas = data.carpetasName
            } catch (e){
                this.error_catch(e)
            }
        },
        async guardarCarpeta(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.slimImagen.instanciaCrop.dataBase64.output.image
                this.model.imagen = archivo? archivo : null
                this.loading = true
                if(this.modoEditar){
                    const {data} = await Carpetas.editarCarpeta(this.model)
                    this.notificacion('Mensaje', 'Carpeta editada', 'success')
                }else{
                    const {data:{data}} = await Carpetas.crearCarpeta(this.model)
                    this.model.id = data.carpeta.id
                    this.modoEditar = true
                    this.notificacion('Mensaje', 'Carpeta creada', 'success')
                    this.id_carpeta = data.carpeta.id
                    this.$router.push( { name: 'mis-cursos.editar.carpeta', params:{id_carpeta: data.carpeta.id}} )
                }
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async listarCarpeta(){
            try {
                const {data:{data}} = await Carpetas.listarCarpeta({id_carpeta:this.id_carpeta})
                this.model.id = data.carpeta.id
                this.model.nombre = data.carpeta.nombre
                this.model.descripcion = data.carpeta.descripcion
                this.cursos = data.cursos
                if (data.carpeta.imagen_firmada){
                    this.$refs.slimImagen.instanciaCrop.load(data.carpeta.imagen_firmada)
                } else {
                    this.$refs.slimImagen.instanciaCrop.remove()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarPosicion(event){
            try {
                const datos = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    id: event.moved.element.id,
                    id_carpeta:this.id_carpeta
                }
                this.loading = true
                const {data} = await Carpetas.ordenarCursoPosicion(datos)
                this.loading = false

                this.notificacion('','Actualizado','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarCurso(idCUrso){
            try {
                const info ={
                    id_curso: idCUrso,
                    id_carpeta: this.id_carpeta
                }
                const {data} = await Carpetas.eliminarCurso(info)
                this.listarCarpeta()
                this.notificacion('Mensaje','Curso eliminado','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        crearCurso(){
            this.$refs.modalCrearCurso.toggle(true)
            

        },
        editarCurso(idCurso){
            this.$router.push({name:'mis-cursos.editar.curso',params:{id_curso:idCurso}})
           
        },
        limpiarForm(){
            this.titulo = 'Crear Carpeta'
            this.loading = false
            this.modoEditar= false
            this.model.id = null
            this.model.nombre = null
            this.model.descripcion = null
            this.$refs.slimImagen.instanciaCrop.remove()

        }

    }
}
</script>

<style lang="scss" scoped>
</style>